import React from "react";

interface PlusIconProps {
    width?: number;
    height?: number;
    fill?: string;
}

const PlusIcon: React.FC<PlusIconProps> = ({ width = 24, height = 24, fill = 'currentColor' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
        </svg>
    )
}

export default PlusIcon;