import React from 'react';
import '../../styles/noti-modals/Completed.css';

interface CompletedProps {
    isCompleted: boolean;
    message: string;
    onClose: () => void;
    children: React.ReactNode;
}

const Completed = ({ isCompleted, message, onClose, children }: CompletedProps): JSX.Element => {
    if (!isCompleted) {
        return <>{children}</>;
    }
    return (
        <div className={`completed-modal ${isCompleted ? "completed" : ""}`}>
            <div className="completed-modal-content">
                <div className="success-checkmark">
                    <div className="check-icon">
                        <span className="icon-line line-tip"></span>
                        <span className="icon-line line-long"></span>
                        <div className="icon-circle"></div>
                        <div className="icon-fix"></div>
                    </div>
                </div>
                <div className="completed-modal-msg">
                    {message.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                    <span className='phone-completed-msg'>1-800-321-6568</span><br />
                    <span className='phone-completed-msg'>1-208-459-1507</span>
                </div>
                {children}
                <div className="close" onClick={onClose}>CLOSE</div>
            </div>
        </div>
    );
}

export default Completed;