import React, { useEffect, useState } from "react";
import logo from '../banner_logo.png';
import '../styles/Menu.css';
import List from '../icons/List';
import { Link, useNavigate } from 'react-router-dom';
import VideoFoundry from './VideoFoundry';

interface Category {
    _id: string;
    categoryNo: string;
    categoryName: string;
}

interface MenuProps {
    categories: Category[];
    navigate: (path: string) => void;
}

const Menu = ({ categories, navigate }: MenuProps): JSX.Element => {
    const [showProductsDropdown, setShowProductsDropdown] = useState(false);
    const [showFoundryDropdown, setShowFoundryDropdown] = useState(false);
    const [showAboutDropdown, setShowAboutDropdown] = useState(false);
    const [showMainMenu, setShowMainMenu] = useState(false);
    const [showProductsSubmenu, setShowProductsSubmenu] = useState(false);
    const [showFoundrySubmenu, setShowFoundrySubmenu] = useState(false);
    const [showAboutSubmenu, setShowAboutSubmenu] = useState(false);
    const [showSmallLogo, setShowSmallLogo] = useState(false);
    const [onMobileMenu, setOnMobileMenu] = useState(false);
    const [gridTemplateColumns, setGridTemplateColumns] = useState('0.8fr 0.8fr 0.6fr 0.8fr 1fr 1.2fr 0.8fr 1fr');

    const handleProductsMouseEnter = () => {
        setShowProductsSubmenu(false);
        setShowFoundrySubmenu(false);
        setShowAboutSubmenu(false);
        if (!onMobileMenu) {
            setShowProductsDropdown(true);
        }
    };
    const handleProductsMouseLeave = () => {
        setShowProductsDropdown(false);
    };
    const handleFoundryMouseEnter = () => {
        setShowProductsSubmenu(false);
        setShowFoundrySubmenu(false);
        setShowAboutSubmenu(false);
        if (!onMobileMenu) {
            setShowFoundryDropdown(true);
            setGridTemplateColumns('0.8fr 0.8fr 0.6fr 0.8fr 1fr 1.2fr 0.8fr 1fr');
        }
    };
    const handleFoundryMouseLeave = () => {
        setShowFoundryDropdown(false);
    };
    const handleMenuMouseLeave = () => {
        if (!onMobileMenu) {
            setGridTemplateColumns('0.8fr 0.8fr 0.6fr 0.8fr 1fr 1.2fr 0.8fr 1fr');
        }
    }
    const handleSubFoundryMouseLeave = () => {
        if (!onMobileMenu) {
            setShowFoundryDropdown(false);
            setGridTemplateColumns('0.8fr 0.8fr 0.6fr 0.8fr 1fr 1.2fr 0.8fr 1fr');
        }
    };
    const handleAboutMouseEnter = () => {
        setShowProductsSubmenu(false);
        setShowFoundrySubmenu(false);
        setShowAboutSubmenu(false);
        if (!onMobileMenu) {
            setShowAboutDropdown(true);
            setGridTemplateColumns('0.8fr 0.8fr 0.6fr 0.8fr 1fr 1.2fr 0.8fr 1fr');
        }
    };
    const handleAboutMouseLeave = () => {
        setShowAboutDropdown(false);
    };
    const handleSubAboutMouseLeave = () => {
        if (!onMobileMenu) {
            setShowAboutDropdown(false);
            setGridTemplateColumns('0.8fr 0.8fr 0.6fr 0.8fr 1fr 1.2fr 0.8fr 1fr');
        }
    }

    const handleShowProductSubmenu = () => {
        setShowProductsSubmenu(!showProductsSubmenu);
        setShowFoundrySubmenu(false);
        setShowAboutSubmenu(false);
    }
    const handleShowFoundrySubmenu = () => {
        setShowFoundrySubmenu(!showFoundrySubmenu);
        setShowProductsSubmenu(false);
        setShowAboutSubmenu(false);
    }
    const handleShowAboutSubmenu = () => {
        setShowAboutSubmenu(!showAboutSubmenu);
        setShowProductsSubmenu(false);
        setShowFoundrySubmenu(false);
    }

    const handleMobileMenuClick = () => {
        setGridTemplateColumns('');
        setShowMainMenu(!showMainMenu);
        setShowProductsSubmenu(false);
        setShowFoundrySubmenu(false);
        setShowAboutSubmenu(false);
    };
    const handleMobileMenuOptionClick = () => {
        if (showMainMenu) {
            setShowProductsDropdown(false);
            setShowFoundryDropdown(false);
            setShowAboutDropdown(false);
            handleMobileMenuClick();
        }
    }

    const [isOpen, setIsOpen] = useState(false);
    const openVideo = () => {
        setIsOpen(true);
    };
    const closeVideo = () => {
        setIsOpen(false);
    };

    const navigateToProducts = useNavigate();
    const handleProductsMenuClick = (categoryNo: string) => {
        navigateToProducts(`/products/${categoryNo}`);
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 60) {
                setShowSmallLogo(true);
            } else {
                setShowSmallLogo(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setOnMobileMenu(true);
                setGridTemplateColumns('');
                setShowProductsSubmenu(false);
                setShowFoundrySubmenu(false);
                setShowAboutSubmenu(false);
            } else {
                setOnMobileMenu(false);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="menu">
            <div className="mobile-menu" onClick={handleMobileMenuClick}>
                {showSmallLogo && (
                    <div className="small-logo-mobile-menu">
                        <Link className="redirect" to='/'><img src={logo} alt="R & H Machine, Inc." /></Link>
                    </div>
                )}
                <div className="mobile-menu-line">
                    <p className="mobile-menu-main">MENU</p>
                    <p className="mobile-menu-icon"><List width={32} height={32} fill="#FFFFFF" /></p>
                </div>
            </div>
            <div className={`main-menu ${showMainMenu ? 'show' : ''}`} style={{ gridTemplateColumns }} onMouseLeave={handleMenuMouseLeave}>
                <div className="small-logo-menu">
                    {showSmallLogo && (
                        <Link className="redirect" to='/'><img src={logo} alt="R & H Machine, Inc." /></Link>
                    )}
                </div>
                <p><Link className="redirect" to='/'><span onClick={handleMobileMenuOptionClick}>HOME</span></Link></p>
                <p className="products-menu" onMouseEnter={handleMenuMouseLeave}><Link className="redirect" to='/products'><span className={`${showProductsDropdown ? "products-on-hover" : ""}`} onClick={handleMobileMenuOptionClick} onMouseEnter={handleProductsMouseEnter} onMouseLeave={handleProductsMouseLeave}>PRODUCTS</span></Link><span className="arrow product-arrow" onClick={handleShowProductSubmenu}>&#x25BC;</span></p>
                <p className="foundry-menu"><Link className="redirect" to="/foundry/our-foundry"><span className={`${showFoundryDropdown ? "foundry-on-hover" : ""}`} onClick={handleMobileMenuOptionClick} onMouseEnter={handleFoundryMouseEnter} onMouseLeave={handleFoundryMouseLeave}>FOUNDRY</span></Link><span className="arrow foundry-arrow" onClick={handleShowFoundrySubmenu}>&#x25BC;</span></p>
                <p onMouseEnter={handleMenuMouseLeave}><Link className="redirect" to="/contact-us"><span onClick={handleMobileMenuOptionClick}>CONTACT US</span></Link></p>
                <p onMouseEnter={handleMenuMouseLeave}><Link className="redirect" to="/parts-request"><span onClick={handleMobileMenuOptionClick}>PARTS REQUEST</span></Link></p>
                <p className="about-menu"><span className={`${showAboutDropdown ? "about-on-hover" : ""}`} onClick={handleMobileMenuOptionClick} onMouseEnter={handleAboutMouseEnter} onMouseLeave={handleAboutMouseLeave}>ABOUT US</span><span className="arrow about-arrow" onClick={handleShowAboutSubmenu}>&#x25BC;</span></p>
                <p onMouseEnter={handleMenuMouseLeave}><Link className="redirect" to="/wear-story"><span onClick={handleMobileMenuOptionClick}>WEAR STORY</span></Link></p>
            </div>
            <div className="small-submenu">
                {(showProductsDropdown) && (
                    <div className="menu-dropdown products-submenu three-column-menu" onMouseEnter={handleProductsMouseEnter} onMouseLeave={handleProductsMouseLeave}>
                            {categories.map((category, index) => (
                                <p onClick={() => handleProductsMenuClick(category.categoryNo)} key={category._id} className={`dropdown-item ${index % 3 === 0 ? 'new-line' : ''}`}><span>{category.categoryName}</span></p>
                            ))}
                    </div>
                )}
                <div className="small-submenu-dropdown">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        {(showFoundryDropdown) && (
                            <div className="menu-dropdown foundry-submenu" onMouseEnter={handleFoundryMouseEnter} onMouseLeave={handleSubFoundryMouseLeave}>
                                <p className="dropdown-item"><Link className="redirect" to="/foundry/our-foundry"><span>About Our Foundry</span></Link></p>
                                <p className="dropdown-item"><Link className="redirect" to="/foundry/from-start-to-finish"><span>From Start To Finish</span></Link></p>
                                <p className="dropdown-item" onClick={openVideo}><span>Video Tour of Foundry</span></p>
                                <VideoFoundry isOpen={isOpen} onClose={closeVideo}>
                                    <iframe width="1120" height="630" src="https://www.youtube.com/embed/NiiWhgnIPgs?si=93-yPHG_WSNf6Bmd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </VideoFoundry>
                            </div>
                        )}
                    </div>
                    <div></div>
                    <div></div>
                    <div>
                        {(showAboutDropdown) && (
                            <div className="menu-dropdown about-submenu" onMouseEnter={handleAboutMouseEnter} onMouseLeave={handleSubAboutMouseLeave}>
                                <p className="dropdown-item"><span>What is Chrome Alloy?</span></p>
                                <p className="dropdown-item"><span>Our Location</span></p>
                                <p className="dropdown-item"><span>Our Personnel</span></p>
                                <p className="dropdown-item"><span>Our History</span></p>
                                <p className="dropdown-item"><span>Books</span></p>
                            </div>
                        )}
                    </div>
                    <div></div>
                </div>
                <div className="mobile-submenu">
                    {(showProductsSubmenu) && (
                        <div className="menu-dropdown products-mobile-submenu">
                                {categories.map((category, index) => (
                                    <p key={category._id} className="dropdown-item"><span>{category.categoryName}</span></p>
                                ))}
                        </div>
                    )}
                    <div>
                        {(showFoundrySubmenu) && (
                            <div className="menu-dropdown foundry-mobile-submenu">
                                <p className="dropdown-item"><Link className="redirect" to="/foundry/our-foundry"><span>About Our Foundry</span></Link></p>
                                <p className="dropdown-item"><Link className="redirect" to="/foundry/from-start-to-finish"><span>From Start To Finish</span></Link></p>
                                <p className="dropdown-item"><span>Video Tour of Foundry</span></p>
                            </div>
                        )}
                    </div>
                    <div>
                        {(showAboutSubmenu) && (
                            <div className="menu-dropdown about-mobile-submenu">
                                <p className="dropdown-item"><span>What is Chrome Alloy?</span></p>
                                <p className="dropdown-item"><span>Our Location</span></p>
                                <p className="dropdown-item"><span>Our Personnel</span></p>
                                <p className="dropdown-item"><span>Our History</span></p>
                                <p className="dropdown-item"><span>Books</span></p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;