import React from 'react';
import '../../styles/noti-modals/Processing.css';

interface ProcessingProps {
    isProcessing: boolean;
    message: string;
    children: React.ReactNode;
}

const Processing = ({ isProcessing, message, children }: ProcessingProps): JSX.Element => {
    if (!isProcessing) {
        return <>{children}</>;
    }
    return (
        <div className="processing">
            <div className="processing-content">
                <div className="loader-processing"></div>
                <div className="processing-msg">
                    {message.split('\n').map((line, index) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
                {children}
            </div>
        </div>
    );
}

export default Processing;