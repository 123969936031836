import React from "react";
import newparts_thumb from '../images/common/new_parts_thumb.png';
import logo from '../images/common/banner_logo.png';
import '../styles/Footer.css';
import { Link } from "react-router-dom";

const Footer = (): JSX.Element => {
    return (
        <div className="footer">
            <div className="newparts-contact">
                <div className="contact">
                    <div className="contact-img">
                        <img src={logo} alt="Contact Us" />
                    </div>
                    <div className="contact-info">
                        <p className="title">CONTACT US</p>
                        <div className="address-and-phone">
                            <p className="address">R & H Machine, Inc.<br />115 Roedel Ave.<br />Caldwell, ID 83605</p>
                            <div className="to-call-phone">
                                <p className="to-call">For fast and friendly service,<br />please call toll-free:</p>
                                <p className="phone"><a href="tel:+18003216568">1-800-321-6568</a></p>
                                <p className="phone"><a href="tel:+12084591507">1-208-459-1507</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="newparts">
                    <div className="request-newparts">
                        <p className="title">REQUEST NEW PARTS</p>
                        <p className="intro">We are constantly adding new parts to our line of long wearing Chrome Alloy.  If you are unable to find what you need in the catalog, please contact us.</p>
                        <p className="link"><Link className="redirect" to="/parts-request">CLICK HERE TO REQUEST NEW PARTS</Link></p>
                    </div>
                    <div className="newparts-img">
                        <img src={newparts_thumb} alt="Request New Parts" />
                    </div>
                </div>
            </div>
            <div className="more-contact"><Link className="redirect" to="/contact-us">CLICK HERE FOR MORE WAYS TO CONTACT</Link></div>
            <div className="copyright">&#169; 2010-2024, R & H Machine, Inc.</div>
        </div>
    );
}

export default Footer;