import React, { useState } from "react";
import axios from "axios";
import "../styles/ContactUs.css";
import Processing from "./noti-modals/Processing";
import Completed from "./noti-modals/Completed";
import Error from "./noti-modals/Error";

const ContactUs = (): JSX.Element => {
    const [checkOther, setCheckOther] = useState(false);
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const [checkedCatalog, setCheckedCatalog] = useState('No');
    const [checkedCall, setCheckedCall] = useState('No');
    const [mediaFiles, setMediaFiles] = useState<FileList | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [processingMsg, setProcessingMsg] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [completedMsg, setCompletedMsg] = useState('');
    const [isError, setIsError] = useState(false);

    const handleCheckOther = () => {
        setCheckOther(!checkOther);
    }
    
    const handleCheckValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (checkedValues.includes(value)) {
            setCheckedValues(checkedValues.filter((c) => c !== value));
        } else {
            setCheckedValues([...checkedValues, value]);
        }
    }

    const handleCheckCatalog = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value === 'Yes') {
            setCheckedCatalog('Yes');
        } else {
            setCheckedCatalog('No');
        }
    }
    const handleCheckCall = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value === 'Yes') {
            setCheckedCall('Yes');
        } else {
            setCheckedCall('No');
        }
    }

    // Handle when user upload files
    const handleMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setMediaFiles(files);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Display processing popup modal
        setIsProcessing(true);
        // Set processing message
        if (mediaFiles) {
            setProcessingMsg('PROCESSING YOUR FILE(S) AND MESSAGE...');
        } else {
            setProcessingMsg('SENDING YOUR MESSAGE...');
        }

        const formData = new FormData();
        formData.append('formType', 'contactUs');
        const firstNameInput = (document.getElementById('inFN') as HTMLInputElement).value;
        formData.append('firstName', firstNameInput);
        const lastNameInput = (document.getElementById('inLN') as HTMLInputElement).value;
        formData.append('lastName', lastNameInput);
        formData.append('email', (document.getElementById('inE') as HTMLInputElement).value);
        const phoneNumberInput = (document.getElementById('inPN') as HTMLInputElement).value;
        formData.append('phoneNumber', phoneNumberInput);
        formData.append('homeAddress', (document.getElementById('inHA') as HTMLInputElement).value);
        formData.append('city', (document.getElementById('inC') as HTMLInputElement).value);
        formData.append('state', (document.getElementById('inS') as HTMLInputElement).value);
        formData.append('zipCode', (document.getElementById('inZC') as HTMLInputElement).value);
        formData.append('cropsGrown', (document.getElementById('inCG') as HTMLInputElement).value);
        formData.append('numberOfAcres', (document.getElementById('inNoA') as HTMLInputElement).value);
        formData.append('productsUsed', (document.getElementById('inPU') as HTMLInputElement).value);
        let yourWorkInput = checkedValues.join(', ');
        const yourWorkOtherInput = (document.getElementById('other-input') as HTMLInputElement)?.value;
        if (yourWorkOtherInput) {
            yourWorkInput += `, ${yourWorkOtherInput}`;
        }
        formData.append('yourWork', yourWorkInput);
        formData.append('message', (document.getElementById('inM') as HTMLTextAreaElement).value);
        formData.append('catalog', checkedCatalog);
        formData.append('followUpCall', checkedCall);
        
        let fileNames = [];
        if (mediaFiles) {
            const newFileList = new DataTransfer();
            for (let i = 0; i < mediaFiles.length; i++) {
                const fileExtension = mediaFiles[i].name.split('.').pop();
                const date = Date.now();
                const newFileName = `${firstNameInput}_${lastNameInput}_${phoneNumberInput}_${date}_${i + 1}.${fileExtension?.toLocaleLowerCase()}`;
                const renamedFile = new File([mediaFiles[i]], newFileName, { type: mediaFiles[i].type });
                newFileList.items.add(renamedFile);
                fileNames.push(newFileName);
            }
            formData.append('fileNames', JSON.stringify(fileNames));
            for (let i = 0; i < newFileList.files.length; i++) {
                formData.append('mediaFiles', newFileList.files[i]);
            }
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/form-submit`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                // alert('Your message has been sent successfully!');
                // Close processing modal
                setIsProcessing(false);
                // Remove processing message
                setProcessingMsg('');
                // Display completed modal
                setIsCompleted(true);
                // Set completed message
                setCompletedMsg('YOUR MESSAGE HAVE BEEN SENT SUCCESSFULLY\nIF YOU NEED IMMEDIATE ASSISTANCE, PLEASE CALL:');

            } else {
                // alert('Failed to send your message. Please try again later.');
                // Close processing modal
                setIsProcessing(false);
                // Remove processing message
                setProcessingMsg('');
                // Display error modal
                setIsError(true);
            }
        } catch (error) {
            // console.error('Send message error:', error);
            // alert('Failed to send your message. Please try again later.');
            // Close processing modal
            setIsProcessing(false);
            // Remove processing message
            setProcessingMsg('');
            // Display error modal
            setIsError(true);
        }
        // setIsProcessing(false);
    };

    return (
        <div className="contact-us">
            <div className="contact_us_2">
                <div className="responsive-container-block big-container">
                    <div className="blueBG"></div>
                    <div className="responsive-container-block container">
                        <form className="form-box" onSubmit={handleSubmit}>
                            <div className="container-block form-wrapper">
                                <p className="text-blk contactus-head">
                                    CONTACT US
                                </p>
                                <p className="text-blk contactus-subhead">
                                    <div className="text-blk contactus-subhead-phone">
                                        <p className="phone-explain">For fast and friendly service,<br />please call toll-free:</p>
                                        <p className="phone-sub"><a href="tel:+18003216568">1-800-321-6568</a></p>
                                        <p className="phone-sub"><a href="tel:+12084591507">1-208-459-1507</a></p>
                                    </div>
                                    <div className="text-blk contactus-subhead-address">
                                        <p className="address-sub">R & H Machine, Inc.<br />115 Roedel Ave.<br />Caldwell, ID 83605</p>
                                    </div>
                                </p>
                                <div className="responsive-container-block">
                                    <div className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6" id="i10mt">
                                        <p className="text-blk input-title">
                                            FIRST NAME <span className="required">(required)</span>
                                        </p>
                                        <input className="input" id="inFN" name="FirstName" placeholder="Please enter first name..." required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            LAST NAME <span className="required">(required)</span>
                                        </p>
                                        <input className="input" id="inLN" name="LastName" placeholder="Please enter last name..." required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            EMAIL
                                        </p>
                                        <input className="input" id="inE" name="Email" placeholder="Please enter email..." />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            PHONE NUMBER <span className="required">(required)</span>
                                        </p>
                                        <input className="input" id="inPN" name="PhoneNumber" placeholder="Please enter phone number..." required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            HOME ADDRESS <span className="required">(required)</span>
                                        </p>
                                        <input className="input" id="inHA" name="HomeAddress" placeholder="Please enter your address..." required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            CITY <span className="required">(required)</span>
                                        </p>
                                        <input className="input" id="inC" name="City" placeholder="Please enter your city..." required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            STATE <span className="required">(required)</span>
                                        </p>
                                        <input className="input" id="inS" name="State" placeholder="Please enter your state..." required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            ZIP CODE <span className="required">(required)</span>
                                        </p>
                                        <input className="input" id="inZC" name="ZipCode" placeholder="Please enter your zip code..." required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            CROPS GROWN
                                        </p>
                                        <input className="input" id="inCG" name="CropsGrown" placeholder="" />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            NUMBER OF ACRES
                                        </p>
                                        <input className="input" id="inNoA" name="NumberOfAcres" placeholder="" />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-12 wk-ipadp-12 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            PRODUCTS USED
                                        </p>
                                        <input className="input input-12" id="inPU" name="ProductsUsed" placeholder="" />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-12 wk-ipadp-12 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            YOUR WORK
                                        </p>
                                        
                                        <div className="checkbox-group">
                                            <div>
                                                <input type="checkbox" id="farming" name="YourWork" value="Farming" onChange={handleCheckValues} />
                                                <label htmlFor="farming">Farming</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="sales" name="YourWork" value="Sales" onChange={handleCheckValues} />
                                                <label htmlFor="sales">Sales</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="media" name="YourWork" value="Media" onChange={handleCheckValues} />
                                                <label htmlFor="media">Media</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="research" name="YourWork" value="Research" onChange={handleCheckValues} />
                                                <label htmlFor="research">Research</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="other" name="YourWork" value="Other" onChange={handleCheckOther} />
                                                <label htmlFor="other">Other</label>
                                            </div>
                                        </div>
                                        {checkOther && (
                                            <div className="responsive-cell-block wk-desk-12 wk-ipadp-12 wk-tab-12 wk-mobile-12">                                         
                                                    <input type="input" className="input input-12" id="other-input" name="YourWorkOther" placeholder="Please specify..." />
                                            </div>
                                        )}
                                    </div>
                                    <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i">
                                        <p className="text-blk input-title">
                                            MESSAGE <span className="required">(required)</span>
                                        </p>
                                        <textarea className="textinput" id="inM" name="Message" placeholder="Please enter your message..." required></textarea>
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">
                                            WOULD YOU LIKE A COPY OF OUR CATALOG?
                                        </p>
                                        <div className="radio-btn-group">
                                            <div>
                                                <input type="radio" id="yes-catalog" name="Catalog" value="Yes" onChange={handleCheckCatalog} />
                                                <label htmlFor="yes-catalog">Yes</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="no-catalog" name="Catalog" value="No" onChange={handleCheckCatalog} />
                                                <label htmlFor="no-catalog">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 follow-up-call">
                                        <p className="text-blk input-title">
                                            WOULD YOU LIKE A FOLLOW UP CALL?
                                        </p>
                                        <div className="radio-btn-group">
                                            <div>
                                                <input type="radio" id="yes-call" name="FollowUpCall" value="Yes" onChange={handleCheckCall} />
                                                <label htmlFor="yes-call">Yes</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="no-call" name="FollowUpCall" value="No" onChange={handleCheckCall} />
                                                <label htmlFor="no-call">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12" hidden>
                                        <p className="text-blk input-title">
                                            UPLOAD PHOTOS/VIDEOS/FILES (MAX 200MB)
                                        </p>
                                        <div className="file-input-wrap">
                                            <input type="file" className="file-input" accept="image/*, image/heic, image/heif, video/*, .hevc, .mkv, .doc, .docx, .pdf, .xlsx, .xls, .ppt, .pptx" id="media-upload" multiple onChange={handleMediaChange} name="FileUpload" />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="submit-btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Processing isProcessing={isProcessing} message={processingMsg}>
                {(isProcessing) && (
                    <div style={{color: '#F33030'}} className="processing-msg">DO NOT CLOSE THIS WINDOW</div>
                )}
            </Processing>
            <Completed isCompleted={isCompleted} message={completedMsg} onClose={() => setIsCompleted(false)}>
                <div></div>
            </Completed>
            <Error isError={isError} onClose={() => setIsError(false)}>
                <div></div>
            </Error>
        </div>
    );
};

export default ContactUs;