import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from './images/common/banner_logo.png';
import './App.css';
import Header from './components/Header';
import Menu from './components/Menu';
import Home from './components/Home';
import Products from './components/Products';
import OurFoundry from './components/foundry/OurFoundry';
import FromStartToFinish from './components/foundry/FromStartToFinish';
import ContactUs from './components/ContactUs';
import PartsRequest from './components/PartsRequest';
import WearStory from './components/WearStory';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

interface Category {
  _id: string;
  categoryNo: string;
  categoryName: string;
  categoryPDF: string;
}

function App() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Get categories error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const navigate = (path: string) => {
    window.history.pushState({}, '', path);
    setCurrentPath(path);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="logo-loading">
          <img src={logo} alt="logo" />
        </div>
        <div className="loading-text"></div>
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="sticky-menu">
          <Menu categories={categories} navigate={navigate} />
        </div>
        <Routes>
          <Route path="/" element={<Home categories={categories} />} />
          <Route path="/products" element={<Products categories={categories} />} />
          <Route path="/products/:category" element={<Products categories={categories} />} />
          <Route path="/foundry/our-foundry" element={<OurFoundry />} />
          <Route path="/foundry/from-start-to-finish" element={<FromStartToFinish />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/parts-request" element={<PartsRequest />} />
          <Route path="/wear-story" element={<WearStory />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
