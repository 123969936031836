import React from "react";
import ws1Left from "../images/common/wear-story/wear_rh114_left.png"
import ws1Right from "../images/common/wear-story/wear_rh114_right.png"
import ws2Left from "../images/common/wear-story/wear_rhcone_left.png"
import ws2Right from "../images/common/wear-story/wear_rhcone_right.png"
import ws3Left from "../images/common/wear-story/wear_rhchisel_compare1.png"
import ws3Right from "../images/common/wear-story/wear_rhchisel_compare2.png"
import ws4Left from "../images/common/wear-story/wear_shovel_standard.png"
import ws4Right from "../images/common/wear-story/wear_shovel_rhmachine.png"
import '../styles/WearStory.css';

const WearStory = (): JSX.Element => {
    return (
        <div className="wear-story">
            <div className="wear-story-wrapper">
                <div className="wear-story-content">
                    <div className="wear-story-title">WEAR STORY</div>
                    <div className="ws-text ws-text-1">
                        <p>Every customer of R & H Machine has his own wear story. Thousands of farmers have seen for themselves the advantages of using Chrome Alloy wear parts. Each farmer's soil is different with varying degrees of abrasion, but all have seen a reduction in wear when using our parts. Below are just a few examples of the results that some of our customers have seen when running our parts on their equipment.</p>
                    </div>
                    <div className="ws-image ws-image-1">
                        <div className="ws-image-inner ws-image-left">
                            <p>R & H Chrome Alloy</p>
                            <img src={ws1Left} alt="Wear Story" />
                            <p>4,000 Acres</p>
                        </div>
                        <div className="ws-image-inner ws-image-right">
                            <p style={{paddingLeft: '2em'}}>R & H / Standard</p>
                            <img src={ws1Right} alt="Wear Story" />
                            <p>600 Acres<br />Acre for Acre</p>
                        </div>
                    </div>
                    <div className="ws-text ws-text-2">
                        <p>The examples above were run on two Southwest Idaho farms, and show the comparison between standard steel S-tine points and our Chrome Alloy points. The parts on the left show a new R & H Chrome Alloy point and one that has run 4,000 acres. The parts on the right ran 600 acres in a one-to-one comparison between standard steel and R & H Chrome Alloy points. Also shown are the new parts to the outside of each worn point. You can see the much longer life of the R & H Chrome Alloy point, as it eventually outwore 44 of the steel parts without changing the bolt.</p>
                    </div>
                    <div className="ws-image ws-image-2">
                        <div className="ws-image-inner ws-image-left">
                            <p>R & H Chrome Alloy</p>
                            <img src={ws2Left} alt="Wear Story" />
                        </div>
                        <div className="ws-image-inner ws-image-right">
                            <p>Standard</p>
                            <img src={ws2Right} alt="Wear Story" />
                        </div>
                    </div>
                    <p className="potato-digger-title">Run side by side for thirty days -- Warden, Washington</p>
                    <div className="ws-text ws-text-3">
                        <p>The potato-digger parts shown above ran for thirty days at Warden, Washington. The standard cone shows a deep groove cut by the chain, while the R & H Chrome Alloy cone is merely polished where the chain ran over it.</p>
                    </div>
                    <div className="ws-image ws-image-3">
                        <div className="ws-image-inner ws-image-left">
                            <p>Standard / R & H</p>
                            <img src={ws3Left} alt="Wear Story" />
                            <p>Acre for Acre</p>
                        </div>
                        <div className="ws-image-inner ws-image-right">
                            <p>R & H / Standard</p>
                            <img src={ws3Right} alt="Wear Story" />
                            <p>Acre for Acre</p>
                        </div>
                    </div>
                    <div className="ws-text ws-text-4">
                        <p>The examples above show two sets of chisel points run side-by-side and acre-for-acre. The ones on the left side were run at Condon, Oregon; the ones on the right were run at Arbuckle, California. In both areas the soil was extremely abrasive.</p>
                    </div>
                    <div className="ws-image ws-image-4">
                        <div className="ws-image-inner ws-image-left">
                            <p>Standard</p>
                            <img src={ws4Left} alt="Wear Story" />
                            <p>New / 150 Acres</p>
                        </div>
                        <div className="ws-image-inner ws-image-right">
                            <p>R & H / Standard</p>
                            <img src={ws4Right} alt="Wear Story" />
                            <p>New / 150 Acres</p>
                        </div>
                    </div>
                    <div className="ws-text ws-text-5">
                        <p>The four-inch incorporating shovels shown above are before and after photos of a standard hardfaced shovel and R & H Chrome Alloy run side by side in Hanford, California. The standard hardfaced shovel was completely worn out after 150 acres. The R & H Chrome Alloy shovel was polished, but still the original size after 2,500 acres. The standard shovel would have had to be replaced 17 times.</p>
                    </div>
                </div>
            </div>
            <p className="end-1">WHICH ONE IS MORE EXPENSIVE?</p>
            <p className="end-2">If you have the time to replace worn parts<br />
            you have the time to figure your savings!</p>
        </div>
    );
};

export default WearStory;