import React, { useState, useEffect } from "react";
import rh_planting from '../../images/common/product_slideshow/rh_planting.png';
import sub_soiling from '../../images/common/product_slideshow/rh_sub-soiling.png';
import cultivating from '../../images/common/product_slideshow/rh_cultivating.png';
import fertilizing from '../../images/common/product_slideshow/rh_fertilizing.png';
import rebuilding from '../../images/common/product_slideshow/rh_rebuilding.png';
import '../../styles/home/ProductSlide.css';

const slides = [
    {
        image: rh_planting,
        name: "PLANTING",
        features: ["Long-wearing parts", "Smoother surface", "Precise seed placement"],
        description: "R & H Chrome Alloy planter shoes and weld-on inserts maintain their shape and depth throughout the life of the part, giving more precise seed placement."
    },
    {
        image: sub_soiling,
        name: "SUB-SOILING",
        features: ["Cost effective", "Saves replacement cost", "Reduces downtime", "Fuel conservation"],
        description: "R & H Machine produces ripper points to fit a wide variety of machine brands and models. The advantage to using R & H ripper points, in addition to long wear, is their ability to selfsharpen and to stay sharp, maintaining penetration throughout the life of the point."
    },
    {
        image: cultivating,
        name: "CULTIVATING",
        features: ["Maintains uniform tillage", "Counter-sunk bolt holes", "Less friction - pulls easier"],
        description: "R & H Machine offers cultivator points and shovels to fit a wide variety of makes and models of cultivators. All are cast of our signature high Chrome Alloy to give extremely long life in abrasive soils. Our shovels maintain original width through the life of the part, helping to keep uniform tillage. As a bonus our parts will pull more easily."
    },
    {
        image: fertilizing,
        name: "FERTILIZING",
        features: ["Provides long life", "Protection for the tubes", "Replaceable wear parts", "Built with high strength steel"],
        description: "We have available completely assembled swept-back and swept-forward knives for your convenience. We have cast Chrome Alloy inserts that can be welded onto the leading and trailing edges of the shank to give exceptional wear and help protect the tube. Custom lengths and tube configurations are available upon request. We also offer replaceable points to fit coil-spring fertilizer shanks."
    },
    {
        image: rebuilding,
        name: "REBUILDING",
        features: ["Lower operating costs", "Keeps shape", "Rebuild worn-out parts"],
        description: "Our Chrome Alloy parts are either customer-installed or may come as complete, ready-to-use units. Corrugator, cultivator, and sweep weld-on parts can be installed onto the leading edge or used to cover the tips of standard parts, allowing you to rebuild your worn-out parts."
    }
]

const ProductSlide = (): JSX.Element => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentSlideIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentSlideIndex - 1;
        setCurrentSlideIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentSlideIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentSlideIndex + 1;
        setCurrentSlideIndex(newIndex);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            goToNext();
        }, 3000);

        return () => clearInterval(interval);
    }, [currentSlideIndex]);

    return (
        <div className="product-slideshow">
            <div className="product-slide">
                <div className="left-arrow arrow" onClick={goToPrevious}>&#10094;</div>
                    <div className="slide-content">
                        <div className="product-info">
                            <div className="product-image">
                                <img className="product-img" src={slides[currentSlideIndex].image} alt={slides[currentSlideIndex].name} />
                            </div>
                            <div className="product-name-ft">
                                <p className="product-name">{slides[currentSlideIndex].name}</p>
                                <ul>
                                    {slides[currentSlideIndex].features.map((feature, index) => (
                                        <li key={index} className="product-feature">{feature}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="product-description">
                            <p>{slides[currentSlideIndex].description}</p>
                        </div>
                    </div>
                <div className="right-arrow arrow" onClick={goToNext}>&#10095;</div>
            </div>
            <div className="slide-index">
                {slides.map((slide, index) => (
                    <p key={index} className={index === currentSlideIndex ? "active" : ""} onClick={() => setCurrentSlideIndex(index)}>{index + 1}</p>
                ))}
            </div>
        </div>
    );
}

export default ProductSlide;