import React from 'react';
import demo_product_thumb from '../../images/common/demo_product_thumb.png'
import '../../styles/home/ProductList.css';
import { Link, useNavigate } from 'react-router-dom';

interface Category {
  _id: string;
  categoryNo: string;
  categoryName: string;
  categoryPDF: string;
}

interface ProductListProps {
  categories: Category[];
}

const ProductList = ({ categories }: ProductListProps): JSX.Element => {
  const navigate = useNavigate();

  const handleCategoryClick = (categoryNo: string) => {
    navigate(`/products/${categoryNo}`);
  }
  const bucketUrl = process.env.REACT_APP_BUCKET_URL;

  return (
    <div className="product-list">
      <p className="title"><Link className="redirect" to="/products"><span>ALL PRODUCTS &#10095;&#10095;&#10095;</span></Link></p>
      <div className="product-list-container">
        {categories.map((category) => (
          <div key={category._id} className="product-list-item">
            <div onClick={() => handleCategoryClick(category.categoryNo)} className="category_image">
              <img src={`${bucketUrl}/${category.categoryNo}.png`} alt="demo_product_thumb" />
            </div>
            <p onClick={() => handleCategoryClick(category.categoryNo)}><span>{category.categoryName}</span></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;