import React from "react";
import '../styles/Header.css';
import logo from '../banner_logo.png';
import SearchIcon from "../icons/Search";
import CartIcon from "../icons/Cart";
import UserIcon from "../icons/User";
import { Link } from 'react-router-dom';

const Header = (): JSX.Element => {
  return (
    <header className="header">
        <div className="logo"><Link className="redirect" to='/'><img src={logo} /></Link></div>
        <div className="sub-header">
            <h1>Chrome Alloy Wear Parts</h1>
            <h2>Setting the Standard for Wear</h2>
        </div>
        <div className="search-bar">
            <input type="text" placeholder="Search for products..." />
            <svg fill="black" height="26" width="26" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 488.4 488.4" xmlSpace="preserve">
            <g>
                <g>
                    <path d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6
                        s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2
                        S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7
                        S381.9,104.65,381.9,203.25z"/>
                </g>
            </g>
        </svg>
        </div>
        <div className="cart" hidden>
            <CartIcon width={36} height={36} fill="black" />
        </div>
        <div className="user">
            <p className="user-first-last-name">John Doe</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="black" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
        </svg>
        </div>
    </header>
  );
};

export default Header;