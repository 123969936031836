import React, { useState } from "react";
import '../../styles/products/Categories.css';

interface Category {
    _id: string;
    categoryNo: string;
    categoryName: string;
    categoryPDF: string;
}

interface CategoriesProps {
    showCategories: boolean;
    categories: Category[];
    onSelectCategory: (selectedCategories: string[], selectedCategoriesFull: Category[]) => void;
}

const Categories = ({ categories, showCategories, onSelectCategory }: CategoriesProps): JSX.Element => {
    const [selectedCategoriesFull, setSelectedCategoriesFull] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const handleSelectCategory = (event: React.MouseEvent<HTMLParagraphElement>) => {
        const category = event.currentTarget.textContent || "";
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter((c) => c !== category));
            setSelectedCategoriesFull(selectedCategoriesFull.filter((c) => c.categoryName !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
            setSelectedCategoriesFull([...selectedCategoriesFull, categories.find((c) => c.categoryName === category) || { _id: "", categoryNo: "", categoryName: "", categoryPDF: "" }]);
        }
    }

    const handleClear = () => {
        setSelectedCategories([]);
        setSelectedCategoriesFull([]);
    }

    const handleSearch = () => {
        onSelectCategory(selectedCategories, selectedCategoriesFull);
    }

    if (!showCategories) {
        return <></>;
    }
    return (
        <div className={`categories-popup ${showCategories ? "open" : ""}`}>
            <div className="categories-select">
                <p className="categories-select-title">You can click on multiple categories</p>
                <div className="select-categories">
                    {categories.map((category) => (
                        <p className={`${selectedCategories.includes(category.categoryName) ? "selected" : "not-selected"}`} onClick={handleSelectCategory} key={category._id}>{category.categoryName}</p>
                    ))}
                </div>
                <div className="categories-select-buttons">
                    <p onClick={handleClear} className="categories-select-clear">CLEAR</p>
                    <p onClick={handleSearch} className="categories-select-done">SEARCH</p>
                </div>
            </div>
        </div>
    );
}

export default Categories;