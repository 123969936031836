import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../icons/Plus";
import Categories from "./products/Categories";
import ViewPDF from "./products/ViewPDF";
import "../styles/Products.css";

interface Category {
    _id: string;
    categoryNo: string;
    categoryName: string;
    categoryPDF: string;
}

interface ProductsProps {
    categories: Category[];
}

const Products = ({ categories }: ProductsProps ): JSX.Element => {
    const [showCategories, setShowCategories] = useState(false);
    const [selectedCategoriesFull, setSelectedCategoriesFull] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [searchCategories, setSeachCategories] = useState("All Categories");
    const [currentPDF, setCurrentPDF] = useState("");

    // URL configuration for categories search
    const navigate = useNavigate();
    const { category } = useParams<{ category: string }>();

    const handleShowCategories = () => {
        setShowCategories(!showCategories);
    }
    const handleSelectCategory = (selectedCategories: string[], selectedCategoriesFull: Category[]) => {
        setSelectedCategoriesFull(selectedCategoriesFull);
        setSelectedCategories(selectedCategories);
        setShowCategories(false);
    }

    const handleChoosePDF = (pdf: string, categoryNo: string) => {
        setCurrentPDF(pdf);
        navigate(`/products/${categoryNo}`);
    }

    // Remove this later
    const handleOpenPDFMobile = (pdf: string) => {
        window.open(pdf, '_self');
    }

    useEffect(() => {
        if (selectedCategories.length > 0) {
            setSeachCategories(selectedCategories.join(", "));
        } else {
            setSeachCategories("All Categories");
        }
    }, [selectedCategories]);

    useEffect(() => {
        if (currentPDF === "") {
            setCurrentPDF(categories[0].categoryPDF);
        }
    }, []);

    useEffect(() => {
        if (category) {
            const selectedCategoryObject = categories.find((c) => c.categoryNo === category);
            if (selectedCategoryObject) {
                setCurrentPDF(selectedCategoryObject.categoryPDF);
            } else {
                alert("Category not found");
                navigate("/products");
            }
        } else {
            setCurrentPDF(categories[0].categoryPDF);
        }
    }, [category]);
    return (
        <div className="products">
            <div className="sort-select-search" hidden>
                <div className="sort-select">
                    <div className="categories-and-sort">
                        <div className="select-categories" onClick={handleShowCategories}>
                            <p className="categories-trigger">Select Categories</p>
                            <PlusIcon />
                        </div>
                        <div className="sort-products-by">
                            <p className="sort-by">Sort By:</p>
                            <select name="sort-products" id="sort-products">
                                <option value="name">Name</option>
                                <option value="code">Code</option>
                                <option value="category">Category</option>
                            </select>
                        </div>
                    </div>
                    <div className="quick-search">
                        <input type="text" placeholder="Quick Search" />
                        <p className="search-btn">Search</p>
                    </div>
                </div>
                <div className="sort-select-query">
                    <p className="search-query"><b>Searching for: </b>{searchCategories}</p>
                </div>
            </div>
            <div className="categories-options">
                <Categories showCategories={showCategories} categories={categories} onSelectCategory={handleSelectCategory} />
            </div>
            <div className="view-pdf">
                <div className="view-pdf-by-choice">
                    <p className="view-pdf-options">View PDF File of:</p>
                    {/* <div className="view-pdf-mobile">
                        <select name="categories-list" id="categories-list" onChange={(e) => handleChoosePDF(e.target.value)}>
                            {categories.map((category) => (
                                <option key={category._id} value={category.categoryPDF}>{category.categoryName}</option>
                            ))}
                        </select>
                        <p className="view-pdf-full-mobile" onClick={() => {window.open(currentPDF, '_blank')}}>Full
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg>
                        </p>
                    </div> */}
                    {categories.map((category) => (
                        <div className="list-and-full-mobile">
                            <p key={category._id} className="view-pdf-option-mobile" onClick={() => handleOpenPDFMobile(category.categoryPDF)}>{category.categoryName}</p>
                        </div>
                    ))}
                    {categories.map((category) => (
                        <div className="list-and-full">
                            <p key={category._id} className="view-pdf-option" onClick={() => handleChoosePDF(category.categoryPDF, category.categoryNo)}>{category.categoryName}</p>
                            <p className="view-pdf-full" onClick={() => {window.open(category.categoryPDF, '_blank')}}>Full
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M20 14a1 1 0 0 0-1 1v3.077c0 .459-.022.57-.082.684a.363.363 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.571-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684L4.999 5.5a.5.5 0 0 1 .5-.5l3.5.005a1 1 0 1 0 .002-2L5.501 3a2.5 2.5 0 0 0-2.502 2.5v12.577c0 .76.083 1.185.32 1.627.223.419.558.753.977.977.442.237.866.319 1.627.319h12.154c.76 0 1.185-.082 1.627-.319.419-.224.753-.558.977-.977.237-.442.319-.866.319-1.627V15a1 1 0 0 0-1-1zm-2-9.055v-.291l-.39.09A10 10 0 0 1 15.36 5H14a1 1 0 1 1 0-2l5.5.003a1.5 1.5 0 0 1 1.5 1.5V10a1 1 0 1 1-2 0V8.639c0-.757.086-1.511.256-2.249l.09-.39h-.295a10 10 0 0 1-1.411 1.775l-5.933 5.932a1 1 0 0 1-1.414-1.414l5.944-5.944A10 10 0 0 1 18 4.945z" fill="currentColor"/></svg>
                            </p>
                        </div>
                    ))}
                </div>
                <div className="pdf-iframe">
                    <iframe src={currentPDF} width={"120%"} height={"100%"} />
                </div>
            </div>
        </div>
    );
}

export default Products;