import React, {useEffect, useState} from "react";
import images from '../../importImages';
import '../../styles/home/Overview.css';
import ProductSlide from "./ProductSlide";

const Overview = (): JSX.Element => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((currentImageIndex) => (currentImageIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="overview">
            <div className="brief">
                <p>
                Our goal at R & H is to provide the longest wearing replacement parts available to the Agricultural community with unrivaled quality. R & H Chrome Alloy wear parts are made entirely in our plant in Caldwell, Idaho. We cast our parts of an extremely durable material which makes our parts the longest lasting on the market. We have almost fifty years of satisfied customers who agree with us.
                </p>
            </div>
            <div className="slideshow">
                <div className="image-slide">
                    <img src={images[currentImageIndex]} alt="overview" />
                </div>
                <ProductSlide />
            </div>
        </div>
    );
}

export default Overview;