import React from 'react';
import foundry1 from '../../images/common/foundry/foundry_1.jpg';
import foundry2 from '../../images/common/foundry/foundry_2.jpg';
import foundry3 from '../../images/common/foundry/foundry_3.jpg';
import foundry4 from '../../images/common/foundry/foundry_4.jpg';
import '../../styles/foundry/OurFoundry.css';

const OurFoundry = (): JSX.Element => {
    return (
        <div className="foundry">
            <div className="foundry-title">
                <p>WHAT IS OUR FOUNDRY?</p>
            </div>
            <div className="foundry-content">
                <div className="description des-1">
                    <div className="description-text des-1-text">
                        <p><span>GEM STATE ALLOYS, INC. was founded in 1979 to meet the casting needs of <strong>R & H MACHINE, INC.</strong> and several OEM customers throughout the United States. In 2006 both companies were merged to streamline our operation. We specialize in manufacturing the longest wearing Chrome Alloy castings for use in agriculture, including tillage-tool tips, planter shoes, fertilizer points, sub-soiler points, forage harvester parts, and replacement parts for potato and sugar beet harvesters. Other applications include tips for tub-grinders, and skid plates to fit snow plows. With our "in-house" manufacturing facility and long-term, dependable employees, we are able to maintain our high level of quality control. We are also able to make special-order parts in a fairly short period of time. Each part is matched with the best metal for the job, and cast for that use. Our parts last ten to twenty times longer than factory or factory-hardfaced parts.</span></p>
                    </div>
                    <div className="description-image des-1-img">
                        <img src={foundry1} alt="Our Foundry" />
                    </div>
                </div>
                <div className="quarter-circle quarter-circle-1"><div className="draw draw1"></div></div>
                <div className="description des-2">
                    <div className="description-image des-2-img">
                        <img src={foundry2} alt="Our Foundry" />
                    </div>
                    <div className="description-text des-2-text">
                        <p><span>We pour five different alloys of high chrome and manganese steel. The components of each alloy that we cast are weighed carefully and placed into an electric induction furnace, melted, and heated to between 2,800 and 3,000 degrees F depending on the metal being poured. Each batch of metal weighs 1,200 pounds, takes approximately one hour from start to finish, and is accurately formulated to within two decimals to insure consistency of our parts. Because we have control of the foundry, we guarantee that the chemistry of each part has not changed since our first part was cast almost fifty years ago. The metal is poured into sand molds, allowed to cool and then removed from the sand. The sand is then recycled and reused. Next, the casting is cleaned up, ground, and shipped to you, the customer. Some of the castings are used in our welding shop, where they are welded onto steel fabrications.</span></p>
                    </div>
                </div>
                <div className="description des-3">
                    <div className="description-text des-3-text">
                        <p><span>The metals used in making each part are chromium, manganese, silicone, molybdenum, carbon, and scrap steel. All materials except the scrap steel are shipped in from suppliers on the west coast. Scrap steel is abundant in the Treasure Valley with our varied industries. This area also has an abundant and reliable labor force, as well as some of the lowest energy costs in the nation.</span></p>
                    </div>
                </div>
                <div className="quarter-circle quarter-circle-2"><div className="draw draw2"></div></div>
                <div className="description des-4">
                    <div className="description-text des-4-text">
                        <p><span>We have endeavored over the years to maintain an extremely safe workplace and our entire process from start to finish has been thoroughly designed and checked to minimize or eliminate exposure to hazardous materials. Our casting molds are made using a water-soluble and environmentally friendly binder system, which, though more expensive, is not harmful to our employees or the environment. The olivine sand that we use is a crushed rock product designed to give a superior casting finish. It has no free silica, resulting in a much safer and user-friendly process. Each area of exposure has been carefully examined, and the safest material or process has been used. Our sand is reclaimed and reused a number of times, before it is eventually discarded for use as clean fill.</span></p>
                    </div>
                    <div className="description-image des-4-img">
                        <img src={foundry3} alt="Our Foundry" />
                    </div>
                </div>
                <div className="quarter-circle quarter-circle-3"><div className="draw draw3"></div></div>
                <div className="description des-5">
                    <div className="description-image des-5-img">
                        <img src={foundry4} alt="Our Foundry" />
                    </div>
                    <div className="description-text des-5-text">
                        <p><span>With our in-house foundry, and its environmentally clean processes and convenient location, we are able to supply the parts you need, while being a good neighbor. Each part we make will give you many times the life of standard parts, also reducing waste. You can count on <strong>R & H Machine</strong> to supply the highest quality Chrome Alloy wear parts possible, thereby lowering your cost per acre.</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurFoundry;