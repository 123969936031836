import React from "react";
import "../styles/VideoFoundry.css";

interface VideoFoundryProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const VideoFoundry = ({ isOpen, onClose, children }: VideoFoundryProps): JSX.Element => {
    if (!isOpen) {
        return <></>;
    }

    return (
        <div className={`video-foundry ${isOpen ? "open" : ""}`}>
            <div className="video-foundry-content">
                <span className="close" onClick={onClose}>&times;</span>
                {children}
            </div>
        </div>
    );
};

export default VideoFoundry;