import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import fstf01 from "../../images/common/fstf/foundrystf_01.jpg";
import fstf02 from "../../images/common/fstf/foundrystf_02.jpg";
import fstf03 from "../../images/common/fstf/foundrystf_03.jpg";
import fstf04 from "../../images/common/fstf/foundrystf_04.jpg";
import fstf05 from "../../images/common/fstf/foundrystf_05.jpg";
import fstf06 from "../../images/common/fstf/foundrystf_06.jpg";
import fstf07 from "../../images/common/fstf/foundrystf_07.jpg";
import fstf08 from "../../images/common/fstf/foundrystf_08.jpg";
import fstf09 from "../../images/common/fstf/foundrystf_09.jpg";
import fstf10 from "../../images/common/fstf/foundrystf_10.jpg";
import '../../styles/foundry/FromStartToFinish.css';

const slides = [
    {
        image: fstf01,
        text: "Our goal at <strong>R & H Machine</strong> is to provide the longest wearing replacement parts available to the agricultural community with unrivalled quality. R & H high Chrome Alloy wear parts are manufactured entirely in our plant in Caldwell, Idaho. Our parts are cast of an extremely durable material which makes our parts the longest lasting on the market. We have over fifty years of satisfied customers who agree with us. To best understand how we achieve this goal, we would like to describe our manufacturing process."
    },
    {
        image: fstf02,
        text: "Our molding sand is a crushed rock product specifically designed to give a superior casting finish. The molds are made of a water-soluble binder and catalyst system which, though expensive, is not harmful to our employees or the environment. Our entire process from start to finish has been thoroughly designed and checked to minimize or eliminate exposure to hazardous materials. The sand is mixed and packed into a pattern, and allowed to harden. The pattern is then turned over and lifted from the packed sand, resulting in one half of a mold. The other half is made in the same manner. As our metal cannot be drilled or tapped, any bolt holes must be provided by cores. The cores, which also provide cavities within the parts, are now added before the two halves are glued together. After the mold is complete, it is stacked on a pallet, labeled, and allowed to dry."
    },
    {
        image: fstf03,
        text: "In our pouring process each element of our special metal, which includes: chromium, molybdenum, manganese, nickel, carbon, silicon, and mild steel scrap, is weighed to two one-hundredths of a pound to ensure the highest quality possible. We use approximately seventy-five percent recycled material in the production of our metal. Our carefully weighed ingredients are then loaded into our induction furnace, and melted to 2800 to 3000 degrees, depending on the type of metal being poured. After the metal is ready, to ensure our high quality, the impurities and slag are removed, and the temperature is checked. The molds are laid out on a sand-covered floor, while the alloys are being melted. The loose sand will capture any spilled metal. Weights are placed on the molds to prevent the tops from lifting off. The pouring ladle is then brought to the furnace and filled. The ladle is then brought to the pouring floor, and each mold is filled with molten metal. The metal is allowed to harden, before being removed from the floor, and cooled. As you can see, the entire part has been cast of the same material. This makes R & H Chrome Alloy parts the same inside and out, unlike a hard-faced product, which only gives a hard surface."
    },
    {
        image: fstf04,
        text: "The castings are then removed from the sand mold, separated from the runner system, and cleaned in a steel-shot blaster. The grinding department will now complete the manufacturing process. Each part is hand-ground to remove the parting line where the two halves of the mold met. All bolt holes are checked with a bolt to ensure that every core has been cleaned out and will fit properly on the customers’ equipment. The surface of each part is checked to make sure it is free of rough areas. Our parts, like planter shoes, are smoothed on a belt sander. Each part will have been checked a minimum of three times before it leaves the grinding department. The runner system and any part with defects are returned to the melting department to be recycled into a new batch of metal. The finished castings are now ready for shipment to our warehouse and ultimately our valued customers throughout the country."
    },
    {
        image: fstf05,
        text: "We have an in-house welding shop, where we produce R&H high quality, long-wearing ripper points, fertilizer knives, chisel points, and shin guards. All of our fabricated parts are heated in our 1,000,000 BTU furnace to a bright cherry red before they are welded. The heating fully expands the metals and reduces thermal shock in the weld, reducing cracking and making a durable weld. The blending of metals makes a weld similar to stainless steel, resulting in a strong, wear-resistant part."
    },
    {
        image: fstf06,
        text: "<strong>R & H Machine</strong>’s ripper points are constructed of long-wearing Chrome Alloy wear material as well as steel. The Chrome Alloy cap gives the finished point long life, and the steel attachment fabrication gives durability and a means to attach it to the shank. The base of the entire part is a slab of T-1 steel, which has 2-1/2 times the bend strength of mild steel. In fact, it has been used extensively in protecting our troops inside the military Hummer. With this strong foundation as a base, we then add side plates, to form a boot, which is custom fit for each particular model of ripper. We have hundreds of templates for different manufacturers and models. We even build points for home-made or custom rippers."
    },
    {
        image: fstf07,
        text: "Each R & H ripper point boot is assembled on a jig to make sure it will fit properly. The boot is then tacked and welded. Once the boot is complete, the Chrome Alloy cap is tacked in place. Each Chrome Alloy casting is slightly wider than the base to help protect the welds from excessive wear. After the Chrome Alloy cap is tacked on, it is now heated to a bright cherry red. This step is critical in the integrity of the finished part. After being heated, the ripper point is removed from the furnace, and immediately the Chrome Alloy cap and the boot are welded together. The completed ripper point is now ready to be cooled on a rack, and then cleaned in our tumblast machine, which tumbles the point and shoots it with steel shot. This process not only removes any scale, but stress-relieves the welds resulting in a stronger, more durable ripper point. It is then inspected and sent to our warehouse. The R & H ripper point is now ready to be used by our customers in their fields."
    },
    {
        image: fstf08,
        text: "R & H manufactures shin guards of various sizes to fit different widths of ripper shanks. We do this by using different sizes of both steel backings and Chrome Alloy wear pieces welded together. Our most popular shin guards range in size from 1-1/4 to 3 inches wide, and are 3 inches long. After the backing is welded onto our Chrome Alloy cap, it can be tacked onto your shank without heating for ease of installation."
    },
    {
        image: fstf09,
        text: "<strong>R & H Machine</strong> also manufactures finished assemblies for fertilizer knives and chisels. Our fertilizer knives use a Chrome Alloy insert welded onto the front of the knife, with a tube protector welded onto the rear of the knife. Tubes of various sizes and configurations can be added. We have a variety of shapes and sizes to custom fit your application. Our chisel points use a standard chisel with one of our Chrome Alloy inserts welded onto each end. We offer 2-inch, 3-inch, and 4-inch wide points either pre-welded or available for the farmer to install onto his own worn chisels."
    },
    {
        image: fstf10,
        text: "With the proven combination of steel and Chrome Alloy, you can expect our parts to last 3 to 5 times longer than hard-faced points. If you haven’t tried our points, we recommend running one of ours alongside what you are currently using. We think you will be pleased with the results."
    }
];

const FromStartToFinish = (): JSX.Element => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        const isLastSlide = currentSlide === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentSlide + 1;
        setCurrentSlide(newIndex);
    };

    const previousSlide = () => {
        const isFirstSlide = currentSlide === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentSlide - 1;
        setCurrentSlide(newIndex);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: nextSlide,
        onSwipedRight: previousSlide,
    });

    return (
        <div className="from-start-to-finish" {...swipeHandlers}>
            <div className="from-start-to-finish-content">
                <div className="fstf-arrow fstf-arrow-left" onClick={previousSlide}>&#10094;</div>
                <div className={`fstf-slide fstf-slide-${currentSlide}`}>
                    <div className={`fstf-slide-image fstf-slide-${currentSlide}-image`}>
                        <img src={slides[currentSlide].image} alt="From Start To Finish" />
                    </div>
                    <div className={`fstf-slide-text fstf-slide-${currentSlide}-text`}>
                        <p dangerouslySetInnerHTML={{ __html: slides[currentSlide].text }} />
                    </div>
                </div>
                <div className="fstf-arrow fstf-arrow-right" onClick={nextSlide}>&#10095;</div>
            </div>
            <div className="made-in">PROUDLY MADE IN CALDWELL, IDAHO, USA</div>
        </div>
    )
};

export default FromStartToFinish;