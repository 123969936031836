import React, { useState } from 'react';
import foundry from '../../images/common/foundry.png';
import '../../styles/home/FoundryO.css';
import VideoFoundry from '../VideoFoundry';
import Youtube from '../../icons/Youtube';

const FoundryO = (): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const openVideo = () => {
        setIsOpen(true);
    };
    const closeVideo = () => {
        setIsOpen(false);
    };

    return (
        <div className="foundry-overview">
            <div className="image">
                <img src={foundry} alt="Foundry" />
            </div>
            <div className="description">
                <p className="title">OUR FOUNDRY</p>
                <p className="learn-more">Learn More &#10095;&#10095;&#10095;</p>
                <p className="brief-foundry">With our "in-house" manufacturing facility and long-term, dependable employees, we are able to maintain a high level of quality control. We are also able to make special-order parts in a fairly short time period.</p>
                <div className="video-tour" onClick={openVideo}>
                    <p className="video">WATCH THE VIDEO TOUR</p>
                    <div className="youtube-icon">
                        <Youtube width={40} height={40} fill="#F33030" />
                    </div>
                </div>
            </div>

            <VideoFoundry isOpen={isOpen} onClose={closeVideo}>
                <iframe width="1120" height="630" src="https://www.youtube.com/embed/NiiWhgnIPgs?si=93-yPHG_WSNf6Bmd" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </VideoFoundry>
        </div>
    );
}

export default FoundryO;