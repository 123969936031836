import React from 'react';
import '../../styles/noti-modals/Error.css';

interface ErrorProps {
    isError: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Error = ({ isError, onClose, children }: ErrorProps): JSX.Element => {
    if (!isError) {
        return <>{children}</>;
    }
    return (
        <div className={`error-modal ${isError ? "error-yes" : ""}`}>
            <div className="error-modal-content">
                <div className="error-container">
                    <div className="circle-border"></div>
                    <div className="circle">
                        <div className="error"></div>
                    </div>
                </div>
                <div className="error-modal-msg">
                    SOMETHING WRONG!<br />
                    PLEASE CALL US FOR IMMEDIATE ASSISTANCE<br />
                    <span className="phone-number">1-800-321-6568</span><br />
                    <span className="phone-number">1-208-459-1507</span>
                </div>
                <div className="close" onClick={onClose}>CLOSE</div>
                {children}
            </div>
        </div>
    );
}

export default Error;