import React from "react";
import Overview from "./home/Overview";
import ProductList from "./home/ProductList";
import FoundryO from "./home/FoundryO";

interface Category {
    _id: string;
    categoryNo: string;
    categoryName: string;
    categoryPDF: string;
}

interface HomeProps {
    categories: Category[];
}

const Home = ({ categories }: HomeProps): JSX.Element => {
    return (
        <div>
            <Overview />
            <ProductList categories={categories} />
            <FoundryO />
        </div>
    );
};

export default Home;